import { getDictLists } from '@/filters/fromDict'
import fileUpdate from '../components/file-update.vue'
import {
  formatDate
} from '@/utils/auth/common'
// 付款情况
export const regulatoryAccount = (content) => {
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '监管账户',
      prop: 'fileName',
      attrs: {
        placeholder: '请输入',
        type: 'Amount'
      },
      cols
    },
    {
      label: '开户行',
      prop: 'fileName',
      attrs: {
        placeholder: '请输入',
        type: 'Amount'
      },
      cols
    },
    {
      label: '账号',
      prop: 'fileName',
      attrs: {
        placeholder: '请输入',
        type: 'Amount'
      },
      cols
    }
  ]
}
// 新增账户信息
export const addAccountColumns = () => {
  return [
    {
      label: '户名',
      prop: 'account'
    },
    {
      label: '开户行',
      prop: 'bankName'
    },
    {
      label: '账号',
      prop: 'accountNo'
    },
    {
      label: '账户类型',
      prop: 'accountType'
    }
  ]
}
// 选择账户信息
export const selectAccountColumns = () => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '80px'
    },
    {
      label: '户名',
      prop: 'account',
      align: 'left'
    },
    {
      label: '开户行',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '账号',
      prop: 'accountNo',
      align: 'left'
    },
    {
      label: '账户类型',
      prop: 'accountType',
      formatter: row => {
        const [obj] = getDictLists('SUPPLIER_BANK_TYPE').filter(item => item.dictId === row.accountType.toString())
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 监管账户系统锁定表格数据
export const accountLockTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      maxWidth: '80px'
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      align: 'left'
    },
    {
      label: '监管账户系统锁定是否实现',
      prop: 'lockableState',
      minWidth: '400px'
    }
  ]
}

// 通知书数据
export const noticeTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code'
    },
    {
      label: '项目公司名称',
      prop: 'xmgsName',
      align: 'left'
    },
    {
      label: '联络人姓名',
      prop: 'contactName'
    },
    {
      label: '电话',
      prop: 'phone'
    },
    {
      label: '收件地址',
      prop: 'address',
      align: 'left'
    },
    {
      label: '备注',
      prop: 'remark',
      align: 'left'
    }
  ]
}
// 文件生成与下载
export const fileConfig = (content) => {
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        placeholder: '请生成或上传文件',
        generateFileId: content.fileData.fileId,
        isHiddenBtn: true
      },
      span,
      tag: fileUpdate,
      rules: [
        { required: false, message: '请生成或上传文件', trigger: 'change' }
      ],
      on: {
        generate: (data) => {
          // 生成
          content.saveData('generate')
        },
        upload: (data) => {
          // 上传成功
          content.fileData.fileId = data.fileId
        },
        preview: (data) => {
          // 预览
          content.previewShow()
        }
      }
    }
  ]
}
// 监管账户
// 监管账户
export const supervise = (content) => {
  const span = 6
  return [
    {
      label: '监管账户',
      prop: 'account',
      span
    },
    {
      label: '开户行',
      prop: 'bankName',

      span

    },
    {
      label: '账号',
      prop: 'accountNo',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        maxlength: 30

      },

      on: {
        input: data => {
          // content.accountData.accountNo = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
        }
      },
      span,
      rules: [{ required: true, message: '请输入账号', trigger: ['change'] }]
    }
  ]
}
// 弹窗还款方案
export const repaymentPlanTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '还款日期',
      prop: 'preRepayDate',
      minWidth: '200px',
      HiddenOverflow: false,
      formatter: row => {
        return formatDate(row.preRepayDate, 'YY-MM-DD')
      }
    },
    {
      label: '还款金额（元）',
      prop: 'preRepayAmount',
      minWidth: '200px',
      HiddenOverflow: false
    },
    {
      label: '还款合同',
      prop: 'repayContractName',
      minWidth: '230px',
      HiddenOverflow: false
    },
    {
      label: '还款条件',
      prop: 'repayCondition',
      HiddenOverflow: false,
      minWidth: '200px'
    }
  ]
}
