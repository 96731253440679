<template>
  <!-- 文件生成与下载组件 -->
  <div class="fileUpdate" ref="fileUpdate">
    <el-input v-bind="$attrs" v-on='$listeners' disabled style="flex:1"></el-input>
    <div class="row fl-16">
      <!-- <IconButton v-if="!$attrs.isHiddenBtn" content='生成' icon='iconfont iconshengcheng' @click="generate"></IconButton>
       <el-upload
                action=""
                class="upload-demo"
                :http-request="uploadFile
                "
                :show-file-list="false"
                accept=".doc,.docx"
                v-if="!$attrs.isHiddenBtn"
              >
                <IconButton
                  :content="fileId?'重新上传':'上传'"
                  :icon="fileId?'iconfont iconzhongxinshangchuan':'iconfont iconshangchuan'"
                ></IconButton>
              </el-upload>
      <IconButton content='下载' icon='iconfont iconxiazai' @click="downFile" v-if="fileId"></IconButton> -->
      <IconButton content='下载' icon='iconfont iconxiazai' @click="downFile" v-if="fileId&&fileId!=='0'"></IconButton>
      <IconButton content='预览' icon='iconfont iconyulan' @click="preview" v-if="fileId"></IconButton>

    </div>
  </div>
</template>
<script>
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  name: 'fileUpdate',
  components: { IconButton },
  data () {
    return {
      value: '',
      fileId: ''
    }
  },
  props: {
    generateFileId: String
  },
  watch: {
    generateFileId (val) {
      this.fileId = val
    }
  },
  methods: {
    // 预览
    preview () {
      this.$emit('preview', '')
    },
    // 生成
    generate () {
      this.$emit('generate', 'generate')
    },
    // 文件上传
    uploadFile (param) {
      const formData = new FormData()
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'docx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传docx格式文件')
        return
      }
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          this.success('文件上传成功')
          this.value = res.data.fileName
          this.fileId = res.data.keyId
          this.$emit('input', res.data.fileName)
          this.$emit('upload', { fileId: res.data.keyId, fileName: res.data.fileName })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    // 文件下载
    downFile () {
      if (this.fileId) {
        const params = { keyId: this.fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.fileUpdate{
  display: flex;
  width: 100%;
}
.row{
  display: flex;
}
</style>
