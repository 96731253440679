//闭环信息
<template>
  <div class="closedLoop" :id="scrollBoxId">
    <div >
      <text-title index='01' label="监管账户"  data-title="监管账户" class="modelBox"></text-title>

      <form-info :componentList="superviseForm"  :model="formData.finSuperviseBankAccount" :count="count" ></form-info>
      <!-- 监管账户锁定 -->
      <div class="hand">
        <!-- <p class="handText">*监管账户系统锁定</p> -->
        <title-center :rules="false" name="监管账户系统锁定" />

      </div>
      <base-table
        :columns="accountLockColumn"
        :tableAttrs="{
          data: formData.ddCustodialAccountInfoVOs,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
        <!-- 监管账户锁定 -->
        <template slot="lockableState" slot-scope="scope">

          {{scope.row.lockableState==='1'?'可实现':'其他'}}

          <span v-show="scope.row.lockableState === '0'&&scope.row.unlockableDescription">{{':'+scope.row.unlockableDescription}}</span>
        </template>

      </base-table>
      <!-- 通知书寄送 -->
      <div class="hand">

         <title-center  name="通知书寄送" />

      </div>
      <base-table
        :columns="noticeColumn"
        :tableAttrs="{
          data: formData.ddSendingNoticesAddressVOs,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="code" slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>

      </base-table>
    </div>
    <div >
      <text-title index='02' label="贷后管理措施" data-title="贷后管理措施" class="modelBox"></text-title>
      <div class="measures">

        <el-input
          type="textarea"
          placeholder="请输入"
          v-model="postloanManagementMeasuresVO.postloanManagementMeasures"
          maxlength="500"
          show-word-limit
          rows="5"
          v-if="postloanManagementMeasuresVO.postloanManagementMeasures"
          :disabled='true'
        ></el-input>
        <span v-else style="padding:10px">--</span>
      </div>
      <enclosure-upload :isHiddenBtn="true" :fileInfos="postloanManagementMeasuresVO.fileInfos"></enclosure-upload>
    </div>
     <div >
      <text-title index='03' label="文件生成与下载" data-title="文件生成与下载" class="modelBox"></text-title>
       <base-form
        :componentList="fileConfig"
        :formAttrs="{
          model: fileData,
          labelWidth: '100px',
        }"
        :showBtns="false"
        class="formData"
        ref="fileForm"
      />
    </div>
    <scroll-fool ref="scrollFool" :scrollData="scrollData" :scrollBoxId='scrollBoxId'/>
     <pre-view  :fileId="fileData.fileId" :fileType="fileData.fileType" :isOpen="true" :count="fileCount" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import FormInfo from '@/components/packages/form-info/form-info-new.vue'
import TitleCenter from '@/pages/business/components/title-center.vue'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
import { contractDdApi } from '@/api/businessApi'
import { phoneReg } from '@/config/reg-config'
import Storage from '@/utils/storage'
import BaseForm from '@/components/common/base-form/base-form.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import EnclosureUpload from '../components/enclosure-upload.vue'
import {
  regulatoryAccount,
  accountLockTable,
  noticeTable,
  supervise,
  fileConfig
} from '../utils/closed-loop-info-config'
export default {
  components: {
    TextTitle,
    baseTable,
    EnclosureUpload,
    BaseForm,
    ScrollFool,
    TitleCenter,
    FormInfo,
    PreView
  },
  props: {

    fileInfos: Object, // 文件信息,
    tabsTitles: Array, // 标题错误状态
    showMain: Number, // 当前显示页面
    postloanManagementMeasuresVO: Object,
    closedData: Object,
    businessId: String
  },
  data () {
    return {
      fileCount: 0,
      showPage: 3,

      gysId: '',
      regulatoryAccountData: { fileName: '8848' }, // 监管账户信息
      count: 0,
      accountLockData: [{}], // 监管账户锁定数据
      othersInfo: '', // 其他补充内容
      noticekData: [{}], // 通知书数据
      measuresTextarea: '', // 措施数据
      scrollBoxId: 'cloBox',
      scrollData: [
        { label: '监管账户', id: 14 },
        { label: '贷后管理措施', id: 15 }
      ]
    }
  },
  watch: {
    showMain (val) {
      if (this.showPage === val) {
        this.$nextTick(() => {
          this.count++
        })
      }
    }
  },
  computed: {
    fileData: {
      get () {
        return this.fileInfos
      },
      set (val) {

      }
    },
    api () {
      return contractDdApi
    },
    fileConfig () {
      return fileConfig(this)
    },
    accounts () {
      return this.formatAccounts(this.formData.accounts)
    },
    formData: {
      get () {
        return this.closedData
      },
      set (val) {
        this.$emit('changeClosedData', val)
      }
    },
    superviseForm () {
      return supervise(this)
    },
    regulatoryAccountList () {
      return regulatoryAccount(this)
    },
    accountLockColumn () {
      return accountLockTable(this)
    },
    noticeColumn () {
      return noticeTable(this)
    }
  },
  methods: {
    // 开发预览暂存
    previewShow () {
      const fileArr = this.fileData.fileName.split('.')
      this.fileData.fileType = fileArr[fileArr.length - 1]
      this.fileCount++
    },

    // 监管账户提交保存
    saveAll (type) {
      const data = this.formData
      data.businessId = this.businessId
      data.ddCustodialAccountInfoDTOs = data.ddCustodialAccountInfoVOs
      data.ddSendingNoticesAddressDTOs = data.ddSendingNoticesAddressVOs
      for (let i = 0; i <= data.ddCustodialAccountInfoDTOs.length; i++) {
        if (data.ddCustodialAccountInfoDTOs[i] && !data.ddCustodialAccountInfoDTOs[i].xmgsName) {
          data.ddCustodialAccountInfoDTOs.splice(i, 1)
          i--
        }
        for (let i = 0; i <= data.ddSendingNoticesAddressDTOs.length; i++) {
          if (data.ddSendingNoticesAddressDTOs[i] && !data.ddSendingNoticesAddressDTOs[i].xmgsName) {
            data.ddSendingNoticesAddressDTOs.splice(i, 1)
            i--
          }
        }
        if (type === 'save') {
          this.api.saveDdAccountInfo(data).then(res => {

          })
        } else if (type === 'submit') {
          if (data.ddCustodialAccountInfoDTOs.length === 0) {
            if (this.showMain === this.showPage) {
              this.warning('请添加监管账户系统锁定信息')
            }
            this.$emit('errMsg', this.showPage, false)
            return false
          }
          this.$refs.fileForm.validate((valid) => {
            if (valid) {
              this.api.submitDdAccountInfo(data).then(res => {
              })
            } else {
              if (this.showMain === this.showPage) {
                this.warning('请生成或上传尽调报告文件')
              }
              this.$emit('errMsg', this.showPage, false)
            }
          })
        }
      }
    },
    // 手机号校验
    blurPhone (data, index) {
      if (!phoneReg.test(data)) {
        this.$set(this.formData.ddSendingNoticesAddressVOs[index], 'phone', '')
        if (this.showMain === this.showPage) {
          this.warning('请输入正确的手机号')
        }
        this.$emit('errMsg', this.showPage, false)
        return false
      }
    },
    formatAccounts (data) {
      data.forEach((item) => {
        item.value = item.xmgsName
      })
      return data
    },
    querySearch (queryString, cb) {
      console.log(queryString, this.formData.accounts)
      const restaurants = this.accounts
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        const key = JSON.parse(JSON.stringify(restaurant))
        console.log(key, 'restaurant')
        return key.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    // 查询尽调监管账户信息 锁定 及通知书寄送信息
    getDdAccountInfo (superviseBankAccountId) {
      this.api.getDdAccountInfo({ businessId: this.businessId, superviseBankAccountId: superviseBankAccountId }).then(res => {
        console.log(res.data, '监管账户信息 锁定 及通知书寄送信息')
        this.formData.ddCustodialAccountInfoVOs = res.data.ddCustodialAccountInfoVOs
        this.formData.ddSendingNoticesAddressVOs = res.data.ddSendingNoticesAddressVOs
      })
    },
    // 选择监管账户
    addSelectAccount (data) {
      this.$set(this.formData.finSuperviseBankAccount, 'account', data.account)
      this.$set(this.formData.finSuperviseBankAccount, 'bankName', data.bankName)
      this.$set(this.formData.finSuperviseBankAccount, 'accountNo', data.accountNo)
      // this.$set(this.formData.finSuperviseBankAccount, 'keyId', data.keyId)
    },
    // 增加锁定数据
    addLockItem () {
      this.formData.ddCustodialAccountInfoVOs.push({ add: true, lockableState: '1' })
    },
    // 删除锁定数据
    delLockItem (index) {
      this.formData.ddCustodialAccountInfoVOs.splice(index, 1)
    },
    // 增加通知书
    addNoticeItem () {
      this.formData.ddSendingNoticesAddressVOs.push({ add: true })
    },
    // 删除通知书
    delDdSendingNoticesAddressVOs (index) {
      this.formData.ddSendingNoticesAddressVOs.splice(index, 1)
    }
  },
  mounted () {
    this.gysId = Storage.getLocal('contractInfo').gysId
  }
}
</script>
<style lang="scss" scoped>
.closedLoop {
  // padding: 10px;
   overflow-y: auto;
}
.addButton {
  background: #4A7CF0;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background-color: #6996FF;
  }
}

.hand {
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  .handText {
    color: red;
  }
}
.othersInput {
  max-width: 120px;
  margin-left: 10px;
}
.measures {
  padding: 10px 0;
}
</style>
