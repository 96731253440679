var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"formContentNew"},[_c('el-row',_vm._l((_vm.componentList),function(item,index){return _c('el-col',_vm._b({key:index},'el-col',_vm.colAttrs(item),false),[(!item.isHidden)?_c('div',{staticClass:"itemNew",class:{ bor: _vm.showbor }},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.label))]),(item.tag)?_c('div',{ref:"valuebox",refInFor:true,staticClass:"value"},[_c(item.tag,_vm._b({tag:"component",model:{value:(_vm.model[item.prop]),callback:function ($$v) {_vm.$set(_vm.model, item.prop, $$v)},expression:"model[item.prop]"}},'component',Object.assign({}, item.attrs),false))],1):_vm._e(),(!item.tag)?_c('el-tooltip',{attrs:{"effect":"dark","content":item.type
              ? _vm.format(
                  item.type,
                  _vm.model[item.prop],
                  item.option,
                  item.selectedField,
                  item.validTimeUnit
                )
              : _vm.model[item.prop] + '',"placement":"top","disabled":_vm.disabledarr[index],"enterable":false}},[_c('div',{ref:"valuebox",refInFor:true,staticClass:"value",class:{ showAll: item.showAll }},[_vm._v(" "+_vm._s(_vm.model[item.prop]?(item.type ? _vm.format( item.type, _vm.model[item.prop], item.option, item.selectedField, item.validTimeUnit ) : _vm.model[item.prop]):'--')+" ")])]):_vm._e()],1):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }