var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"closedLoop",attrs:{"id":_vm.scrollBoxId}},[_c('div',[_c('text-title',{staticClass:"modelBox",attrs:{"index":"01","label":"监管账户","data-title":"监管账户"}}),_c('form-info',{attrs:{"componentList":_vm.superviseForm,"model":_vm.formData.finSuperviseBankAccount,"count":_vm.count}}),_c('div',{staticClass:"hand"},[_c('title-center',{attrs:{"rules":false,"name":"监管账户系统锁定"}})],1),_c('base-table',{attrs:{"columns":_vm.accountLockColumn,"tableAttrs":{
        data: _vm.formData.ddCustodialAccountInfoVOs,
        stripe: true,
      },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"lockableState",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.lockableState==='1'?'可实现':'其他')+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(scope.row.lockableState === '0'&&scope.row.unlockableDescription),expression:"scope.row.lockableState === '0'&&scope.row.unlockableDescription"}]},[_vm._v(_vm._s(':'+scope.row.unlockableDescription))])]}}])}),_c('div',{staticClass:"hand"},[_c('title-center',{attrs:{"name":"通知书寄送"}})],1),_c('base-table',{attrs:{"columns":_vm.noticeColumn,"tableAttrs":{
        data: _vm.formData.ddSendingNoticesAddressVOs,
        stripe: true,
      },"isCaculateHeight":false,"showPage":false},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])})],1),_c('div',[_c('text-title',{staticClass:"modelBox",attrs:{"index":"02","label":"贷后管理措施","data-title":"贷后管理措施"}}),_c('div',{staticClass:"measures"},[(_vm.postloanManagementMeasuresVO.postloanManagementMeasures)?_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入","maxlength":"500","show-word-limit":"","rows":"5","disabled":true},model:{value:(_vm.postloanManagementMeasuresVO.postloanManagementMeasures),callback:function ($$v) {_vm.$set(_vm.postloanManagementMeasuresVO, "postloanManagementMeasures", $$v)},expression:"postloanManagementMeasuresVO.postloanManagementMeasures"}}):_c('span',{staticStyle:{"padding":"10px"}},[_vm._v("--")])],1),_c('enclosure-upload',{attrs:{"isHiddenBtn":true,"fileInfos":_vm.postloanManagementMeasuresVO.fileInfos}})],1),_c('div',[_c('text-title',{staticClass:"modelBox",attrs:{"index":"03","label":"文件生成与下载","data-title":"文件生成与下载"}}),_c('base-form',{ref:"fileForm",staticClass:"formData",attrs:{"componentList":_vm.fileConfig,"formAttrs":{
        model: _vm.fileData,
        labelWidth: '100px',
      },"showBtns":false}})],1),_c('scroll-fool',{ref:"scrollFool",attrs:{"scrollData":_vm.scrollData,"scrollBoxId":_vm.scrollBoxId}}),_c('pre-view',{attrs:{"fileId":_vm.fileData.fileId,"fileType":_vm.fileData.fileType,"isOpen":true,"count":_vm.fileCount}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }