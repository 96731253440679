//附件上传
<template>
<div class="enclosureUpload">
  <div class="text" v-show="fileInfos.length>0">
      <span>附件:</span>
    <el-upload
      class="upload-demo"
      action=""
      :http-request="function (url) {
          return uploadFile(url);
        }
      "
      :show-file-list="false"
      v-show="!isHiddenBtn"
    >
      <icon-button
      content="上传"
      icon="iconfont iconshangchuan"
      />
    </el-upload>
    </div>
  <div class="rightContent">
    <div class="fileBox" v-for="(item,index) in fileList" :key="index" v-show="fileInfos.length>0">
      <el-tooltip
          effect="dark"
          :content="item.fileName"
          placement="top"
          :disabled="disabled"
          :enterable="false"
          v-if="item.showAll"
        >
        <div class="fileName fileStyle" ref="fileName">
          <span>{{item.name+"..."}}</span>
          <span>{{item.suffix}}</span>
        </div>
        </el-tooltip>
        <div v-else class="fileStyle">{{ item.fileName }}</div>
         <icon-button
          content="预览"
          icon="iconfont iconyulan"
          @click="previewShow(item,index)"
          />
           <icon-button
           @click="dowload(item,index)"
           content="下载"
           icon="iconfont iconxiazai"
         />
      <!-- <icon-button
      @click="delfile(item,index)"
      content="删除"
      icon="iconfont iconshanchu1"
         /> -->
          <!-- 预览 -->
  <pre-view :fileId='fileId' :count="count" :fileType="fileType" :isOpen="true" />
    </div>

  </div>
</div>

</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'

export default {
  props: {
    fileInfos: Array,
    isHiddenBtn: Boolean
  },
  components: { IconButton, PreView },
  data () {
    return {
      count: 0,
      fileId: '',
      fileType: '',
      fileName: '',
      showBtn: true,
      name: '',
      suffix: '',
      disabled: true,
      showAll: false
    }
  },
  computed: {
    fileList: {
      get () {
        return this.fileInfos
      },
      set (val) {

      }
    }
  },
  methods: {
    // 下载
    dowload (data, index) {
      if (!data.fileId) {
        this.warning('抱歉暂无资源')
        return
      }
      const params = {
        keyId: data.fileId
      }
      downAPi
        .download(params)
        .then((res) => {
          downFile(res)
        })
        .catch((e) => {
          this.error(e)
        })
    },
    uploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.code === '0') {
          this.showAll = false
          this.fileName = JSON.parse(JSON.stringify(param.file.name))
          this.suffix = param.file.name.substr(param.file.name.lastIndexOf('.') + 1, 4)
          this.name = param.file.name.substr(0, param.file.name.lastIndexOf('.'))
          if (this.name.length > 11) {
            this.name = this.name.substr(0, 11)
            this.showAll = true
          }
          this.showBtn = false
          this.success('文件上传成功')
          this.fileList.push({ name: this.name, suffix: this.suffix, showAll: this.showAll, ...res.data, fileId: res.data.keyId })
          this.$emit('input', res.data.keyId)
          this.$emit('inputSuccess', res.data.fileName)
          this.$nextTick(() => {
            this.getwidth()
          })
        } else {
          this.error('文件上传失败')
        }
      })
    },
    getwidth () {
      const dom = this.$refs.fileName
      this.disabled = dom.offsetWidth >= dom.scrollWidth
    },
    previewShow (item) {
      this.fileId = item.fileId
      const fileArr = item.fileName.split('.')
      this.fileType = fileArr[fileArr.length - 1]
      this.count++
    },
    delfile (data, index) {
      console.log(index)
      this.fileList.splice(index, 1)
      this.fileName = ''
      this.$emit('input', '')
      this.$emit('inputSuccess', '')
      this.showBtn = true
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-textarea {
    height: auto !important;
  }
  .text{
    width: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
  }
  // .enclosureUpload{

  //   // height: 50px;
  // }
  .rightContent{
    width: 100%;
    display: flex;
    justify-content:flex-start;
    flex-wrap: wrap;
    .fileBox{
      padding-right: 50px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
    }
}
.fileStyle{
    font-size: 14px;
    color: #2862E7;
    line-height: 30px;
    padding-right: 20px;
}
// .enclosureUpload{
//   width: 200px;
//   display: flex;
//   justify-content: flex-start;
//   height: 30px;
//   align-items: center;
//   .text{
//     width: 40px;
//   }
//   .rightContent{
//     padding-left: 5px;
//     width: calc(100%-40px);
//     overflow: hidden;
//   }
// }
.fileName{
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
// .hiddenName{
//   white-space:nowrap;
//   overflow:hidden;
//   text-overflow:ellipsis;
// }
// .tip {
//   font-size: 14px;
//   color: #2862E7;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   .fileNameBox{
//     .fileName{
//       width: 100px;
//       white-space:nowrap;
//       overflow:hidden;
//       text-overflow:ellipsis;
//     }
//   }
//   .handleBtn{
//     display: flex;
//     justify-content: flex-end;
//     width: 62px;
//   }
// }
// .iconfont {
//   flex: 1;
//   font-size: 18px;
//   color: #f34145;
//   display: inline-block;
//   cursor: pointer;
// }

</style>
